import store from '@/store'
const title = store.getters.title
export default {
  targetArr: [
    {
      title: `${title}统计数据`,
      list: [{
        label: '流量请求', // label
        width: 100, // 宽度
        prop: 'advRequest', // 属性
        align: 'left', // 对齐方式
        desc: `汇总行表示流量请求，即应用向${title}发起请求的次数；其他行表示广告请求，即${title}向广告平台发起请求的次数`
      }, {
        label: '填充率',
        width: 100,
        prop: 'advRequestFilledRate',
        align: 'left',
        desc: `汇总行表示流量填充率，即应用向${title}发送请求后返回成功的占比；其余行表示广告填充率，即${title}向广告平台发送请求后返回成功的占比`
      }, {
        label: '填充耗时（秒）',
        width: 120,
        prop: 'advResponseTime',
        align: 'left',
        desc: `${title} SDK向广告平台发送请求到填充成功的平均耗时`
      }, {
        label: '展示',
        width: 80,
        prop: 'impress',
        align: 'left',
        desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`
      },
      {
        label: '展示率',
        width: 80,
        prop: 'impressRate',
        align: 'left',
        desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/填充)`
      },
      {
        label: '预估收益',
        width: 100,
        prop: 'income',
        align: 'left',
        desc: `预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`
      },
      {
        label: '点击',
        width: 80,
        prop: 'click',
        align: 'left',
        default: false,
        desc: `${title}统计的广告点击数，由于部分广告平台不提供点击回调，${title}统计的点击数据与广告平台点击数据可能存在差异`
      },
      {
        label: '点击（去重）',
        width: 120,
        prop: 'uniqClick',
        align: 'left',
        default: false,
        desc: `${title}统计的广告点击数反映了用户的实际点击行为，其中包括可能对同一广告的多次点击。去重是对这些数据进行过滤，同一广告被重复点击，只记录一次。`
      },
      {
        label: '点击率',
        width: 80,
        prop: 'clickRate',
        align: 'left',
        default: false,
        desc: `${title}统计的点击率，点击率=点击数/展示数`
      },
      {
        label: '点击率（去重）',
        width: 140,
        prop: 'uniqClickRate',
        align: 'left',
        default: false,
        desc: `${title}统计的点击率(去重)，点击率（去重）=点击(去重)/展示数`
      },
      {
        label: '展示占比',
        width: 100,
        prop: 'display',
        align: 'left',
        default: false,
        desc: 'Mediatom统计的该成广告源展示占比，计算公式：该层广告源展示数/该流量分组或AB测试组总展示数'
      },
      {
        label: '预估收益占比',
        width: 120,
        prop: 'revenuePercentage',
        align: 'left',
        default: false,
        desc: 'Mediatom统计的该层广告源预估收益占比，计算公式：该层广告源预估收益/该流量分组或AB测试组总预估收益；'
      },
      {
        label: '竞价',
        width: 80,
        prop: 'askPriceRequest',
        align: 'left',
        default: false,
        desc: `${title}服务端向竞价广告平台发起的竞价次数；`
      },
      {
        label: '竞价响应率',
        width: 120,
        prop: 'askPriceFilledRate',
        align: 'left',
        default: false,
        desc: `竞价广告平台竞价响应率 = 竞价响应次数 / 竞价次数 * 100%`
      },
      {
        label: '竞价响应eCPM',
        width: 150,
        prop: 'askPrice',
        align: 'left',
        default: false,
        desc: `竞价广告平台在竞价响应时返回的平均eCPM`
      },
      {
        label: '竞价成功率',
        width: 120,
        prop: 'bidSuccessRate',
        align: 'left',
        default: false,
        desc: `-`
      },
      {
        label: '展请率',
        width: 100,
        prop: 'impressRequestRate',
        align: 'left',
        default: false,
        desc: `${title}统计到的展示占${title}收到的请求比例；`,
        sortable: false
      },
      {
        label: '流量填充',
        width: 100,
        prop: 'advResponse',
        align: 'left',
        default: false,
        desc: `汇总行表示流量填充，即应用向${title}发送请求后有广告返回的次数；其余行表示广告填充，即${title}向广告平台发送请求后返回成功次数`,
        sortable: false
      },
      {
        label: '展缓比',
        width: 100,
        prop: 'cacheImpressRate',
        align: 'left',
        default: false,
        desc: `${title} SDK版本大于等于V2.6.3开始支持此字段的统计；当广告位开启缓存之后，Mediatom会统计缓存广告的展示占比，计算公式：展缓比=缓存广告的展示次数/展示总次数*100%`,
        sortable: false
      },
      {
        label: 'CPC',
        width: 100,
        prop: 'cpc',
        align: 'left',
        default: false,
        desc: `预估收益/点击`,
        sortable: false
      },
      {
        label: '广告成交额',
        width: 100,
        prop: 'advPrice',
        align: 'left',
        default: false,
        desc: `${title}平台统计的竞胜后上报给上游广告平台的成交价格之和，该价格仅供参考，实际结算以第三方广告平台为准。`,
        sortable: false
      }
      ]
    },
    {
      title: 'API数据',
      list: [{
        label: '收益API',
        width: 80,
        prop: 'unitRevenue',
        align: 'left',
        desc: `${title}通过Reporting API向广告平台拉取到的收益`
      },
      {
        label: 'eCPM',
        width: 80,
        prop: 'unitEcpm',
        align: 'left',
        desc: `${title}通过Reporting API向广告平台拉取到的收益和展示API计算出eCPM API，公式：（收益/展示API）*1000`
      }, {
        label: '请求API',
        width: 100,
        prop: 'unitRequest',
        align: 'left',
        default: false,
        desc: `${title}通过Reporting API向广告平台拉取到的广告请求数，部分平台不提供此数据`
      },
      {
        label: '填充率API',
        width: 120,
        prop: 'unitFilledRate',
        align: 'left',
        default: false,
        desc: `${title}通过Reporting API向广告平台拉取到的填充率，部分平台不提供此数据`
      },
      {
        label: '展示API',
        width: 100,
        prop: 'unitImpression',
        align: 'left',
        default: false,
        desc: `${title}通过Reporting API向广告平台拉取到的展示`
      },
      {
        label: '点击API',
        width: 100,
        prop: 'unitClick',
        align: 'left',
        default: false,
        desc: `${title}通过Reporting API向广告平台拉取到的点击`
      },
      {
        label: '点击率API',
        width: 120,
        prop: 'unitCtr',
        align: 'left',
        default: false,
        desc: '点击API/展示API'
      }]
    },
    {
      title: '数据GAP',
      list: [{
        label: '点击Gap',
        width: 100,
        prop: 'clickGap',
        align: 'left',
        default: false,
        desc: `${title}统计点击与广告平台统计点击的差异，公式为 点击Gap=(点击-点击API)/点击API`
      },
      {
        label: '展示Gap',
        width: 100,
        prop: 'impressionGap',
        align: 'left',
        default: false,
        desc: `${title}统计展示与广告平台统计展示的差异，公式为 展示Gap=(展示-展示API)/展示API`
      }]
    },
    {
      title: '其他',
      list: [{
        label: '渲染类型',
        width: 100,
        prop: 'adStyle',
        align: 'left',
        default: false,
        desc: `渲染类型`
      }]
    }
  ],
  sureArr: [{
    label: '流量请求', // label
    width: 100, // 宽度
    prop: 'advRequest', // 属性
    align: 'left', // 对齐方式
    desc: `汇总行表示流量请求，即应用向${title}发起请求的次数；其他行表示广告请求，即${title}向广告平台发起请求的次数`
  }, {
    label: '填充率',
    width: 100,
    prop: 'advRequestFilledRate',
    align: 'left',
    desc: `汇总行表示流量填充率，即应用向${title}发送请求后返回成功的占比；其余行表示广告填充率，即${title}向广告平台发送请求后返回成功的占比`
  }, {
    label: '填充耗时（秒）',
    width: 120,
    prop: 'advResponseTime',
    align: 'left',
    desc: `${title} SDK向广告平台发送请求到填充成功的平均耗时`
  }, {
    label: '展示',
    width: 80,
    prop: 'impress',
    align: 'left',
    desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`
  },
  {
    label: '展示率',
    width: 80,
    prop: 'impressRate',
    align: 'left',
    desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/填充)`
  },
  {
    label: '预估收益',
    width: 100,
    prop: 'income',
    align: 'left',
    desc: `预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`
  }, {
    label: '收益',
    width: 80,
    prop: 'unitRevenue',
    align: 'left',
    desc: `${title}通过Reporting API向广告平台拉取到的收益`
  },
  {
    label: 'eCPM',
    width: 80,
    prop: 'unitEcpm',
    align: 'left',
    desc: `${title}通过Reporting API向广告平台拉取到的收益和展示API计算出eCPM API，公式：（收益/展示API）*1000`
  }
  ]
}
