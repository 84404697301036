<template>
  <a-popover
    ref="popover"
    overlayClassName="change-price-wrapper"
    trigger="click"
    arrowPointAtCenter
    @visibleChange="visibleChange"
    destroyTooltipOnHide
  >
    <div class="price-item">
      <span v-if="!item.bidfloor">无底价</span>
      <span style="margin-left: 5px" v-else>¥ {{ item.bidfloor }}</span>
      <a-icon class="primary-text icon" type="edit" />
    </div>
    <a-input
      slot="content"
      ref="input"
      class="input"
      placeholder="无底价"
      v-model="bidfloor"
      prefix="￥"
      size="small"
    >
      <a-icon class="check" slot="addonAfter" type="check" @click="updateBidfloor"/>
    </a-input>
  </a-popover>
</template>

<script>
import { updateSource } from '@/api/aggregate'
export default {
  name: 'RowPriceItem',
  data () {
    return {
      bidfloor: 0
    }
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    visibleChange (e) {
      if (e) {
        this.bidfloor = +this.item.bidfloor === 0 ? '' : this.item.bidfloor
      }
    },
    async updateBidfloor () {
      this.$refs.popover.$children[0].sVisible = false
      const value = this.$refs.input.stateValue
      if (!value) {
        return
      }
      this.$emit('changeLoading')
      const resp = await updateSource({
        ...this.item,
        ...this.query,
        bidfloor: value
      })
      if (resp.code === 200) {
        this.$message.success('修改成功')
        this.$emit('update')
        this.isEdit = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.icon {
  margin-left: 5px;
  opacity: 0;
}
.price-item {
  cursor: pointer;
  &:hover {
    .icon {
      opacity: 1;
    }
  }
}
.input {
  width: 120px;
}
.check {
  cursor: pointer;
}
::v-deep {
  .ant-input-group-addon {
    padding: 0 5px;
  }
  .ant-tooltip-inner{
    background: rgba(0, 0, 0, 0.6)!important;
    color: #fff!important;

  }
}
.price-item-box{
  position: relative;
}
.tip{
  position: absolute;
  top: 0;
  // width: 243px;
  border-radius: 4px;
  transform: translateY(-100%);
  padding: 16px 8px;
}
</style>
