<template>
  <div class="config-container">
    <div class="config_header">
      <a-row class="search_row" type="flex">
        <a-col class="col_item">
          <span class="label">日期：</span>
          <a-range-picker
            format="YYYY-MM-DD"
            style="width: 250px"
            :placeholder="['Start', 'End']"
            @change="ChangeDate"
            :value="rangePickerDate"
            :allowClear="false"
            :ranges="{
              今日: [initDate(0, 0).start, initDate(0, 0).end],
              昨日: [initDate(1, 1).start, initDate(1, 1).end],
              最近一周: [initDate(6, 0).start, initDate(6, 0).end],
              最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
            }"
            :disabledDate="disabledDate"
          />
        </a-col>
        <a-col class="col_item">
          <span class="label">应用：</span>
          <m-select-one
            :disabled="isLoading"
            style="width: 250px"
            label="应用"
            :searchById="true"
            hasOs
            :hasPosition="false"
            :allData="appList"
            v-model="query.appId"
            @change="
              (e) => {
                query.placeId = ''
                getPlaceList()
              }
            "
          />
        </a-col>
        <a-col v-if="placeList.length > 0" class="col_item">
          <span class="label">广告位：</span>
          <m-select-one
            label="广告位"
            :disabled="isLoading"
            :searchById="true"
            :hasPosition="true"
            :allData="placeList"
            style="width: 250px"
            v-model="query.placeId"
            @change="
              () => {
                handleFirst()
                changeRoute()
              }
            "
          />
        </a-col>
        <a-button type="cust" style="margin: 0 0 0 3px" @click="toSetting" v-if="operatorType !== 1">高级设置</a-button>
      </a-row>
      <div class="config_btn" v-if="placeList.length > 0 && placeInfo && !placeInfo.publicAdplace">
        <!-- <a-switch :checked="operatorType === 1" @click="changeOperatorType" />
        <span>{{ operatorType === 2 ? '手动运营' : '自动运营' }}</span> -->
        <div class="radio-item" :class="{ isactive: operatorType === 1 }" @click="changeOperatorType">
          <operabtn class="svg-icon" />自动运营
        </div>
        <div class="radio-item" :class="{ isactive: operatorType === 2 }" @click="changeOperatorType">
          <autobtn class="svg-icon" />手动运营
        </div>
      </div>
    </div>
    <template v-if="query.placeId">
      <AutoManagement
        v-if="operatorType === 1"
        @changeOperatorType="getPlaceList"
        @editSource="editSource"
        :baseQuery="query"
        :appList="appList"
        :placeList="placeList"
      />
      <ManualOperationPage v-if="operatorType === 2" :baseQuery="query" :appList="appList" :placeList="placeList" />
    </template>
    <empty-page :query="query" v-else />
    <FirstModal
      :returnIds="platIds"
      :query="query"
      :isGuidan="isGuidan"
      @modalNext1="modalNext1"
      @modalCancel="firstModalVisible = false"
      :visible="firstModalVisible"
    />
    <SecondModal
      :placeInfo="placeInfo"
      @modalNext2="modalNext2"
      :query="query"
      :isGuidan="isGuidan"
      @modalCancel="secondModalVisible = false"
      @goPrev="handleGoPrev"
      :visible="secondModalVisible"
      :platIds="platIds"
    />
    <ThirdlyModal
      @selectOperatorTypeSuccess="selectOperatorTypeSuccess"
      :query="query"
      @modalCancel="thirdlyModalVisible = false"
      :visible="thirdlyModalVisible"
    />
  </div>
</template>

<script>
import hand from '@/assets/icons/hand.svg?inline'
// import AI from '@/assets/icons/AI.svg?inline'
// import AIlan from '@/assets/icons/AIlan.svg?inline'
import handblue from '@/assets/icons/handBlue.svg?inline'
import operabtn from '@/assets/icons/aggregate/operabtn.svg?inline'
import autobtn from '@/assets/icons/aggregate/autobtn.svg?inline'
import AggregateDetail from './aggregateDetail'
import { updateOperatorSts } from '@/api/autoManagement'
import { getAppList } from '@/api/media'
import { getPlaceListByAppId } from '@/api/common'
import { usedFirstAggergate } from '@/api/aggregateGuide'
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
import ManualOperationPage from './ManualOperationPage'
import AutoManagement from './AutoManagement'
import EmptyPage from './EmptyPage.vue'
import FirstModal from './guidanceModal/FirstModal'
import SecondModal from './guidanceModal/SecondModal'
import ThirdlyModal from './guidanceModal/ThirdlyModal'
const GroupModal = () => import(/* webpackChunkName: "dialog" */ './components/groupModal')
const AbtestModal = () => import(/* webpackChunkName: "dialog" */ './components/abtestModal')
export default {
  name: 'Config',
  components: {
    hand,
    handblue,
    AggregateDetail,
    AbtestModal,
    GroupModal,
    ManualOperationPage,
    AutoManagement,
    EmptyPage,
    FirstModal,
    SecondModal,
    ThirdlyModal,
    operabtn,
    autobtn
  },
  mixins: [mixDate],
  data () {
    return {
      appList: [],
      placeList: [],
      query: {
        dateEnd: '',
        dateStart: '',
        appId: '',
        placeId: ''
      },
      // 弹窗一
      firstModalVisible: false,
      // 弹窗二
      secondModalVisible: false,
      // 弹窗三
      thirdlyModalVisible: false,
      //  引导   编辑
      isGuidan: false,
      // 接收引导弹窗1传过来的广告平台数组
      platIds: [],
      operatorType: 2,
      lastPlace: undefined,
      isLoading: false
    }
  },
  provide () {
    return {
      placeList: () => {
        return this.placeList
      }
    }
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(0, 0)
    this.rangePickerDate = [start, end]
    this.query.dateEnd = endDate
    this.query.dateStart = startDate
  },
  async mounted () {
    await this.getAppList()
    // 获取到应用列表后通过是否有query判断默认选中的应用
    // this.firstModalVisible = true
  },
  computed: {
    placeInfo () {
      return this.placeList.find((item) => item.id === this.query.placeId)
    }
  },
  watch: {},
  methods: {
    changeOperatorType (e) {
      // if (this.operatorType === 1) {
      const self = this
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
            <div class="method-modal" style="height:100px;display:flex;padding:30px">
              <div>
                <a-icon
                  style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:40px"
                  type="exclamation-circle"
                />
              </div>
              <span>
                {this.operatorType === 2
                  ? '停止当前手动运营，开启自动运营。停止后流量将不会进入到手动运营的分组，全部进入到自动运营中。'
                  : '停止自动运营后，流量将按照手动运营中的流量分组进行重新分配，请确保您的手动运营策略配置完善，避免造成流量浪费。'}
              </span>
            </div>
          )
        },
        async onOk () {
          self.changeSts()
        },
        icon: () => {
          return ''
        },
        onCancel () {},
        class: 'method-modal-wrapper',
        width: 600
      })
      // } else {
      //   this.changeSts()
      // }
    },
    async changeSts () {
      const sts = this.operatorType === 1 ? 'S' : 'A'
      const resp = await updateOperatorSts({ sts, placeId: this.query.placeId })
      if (resp.code === 200) {
        this.getPlaceList()
      }
    },
    selectOperatorTypeSuccess (e) {
      this.query = {
        ...this.query
      }
      this.operatorType = e
      this.getPlaceList()
      // const place = this.placeList.find((item) => item.id === this.query.placeId)
      // place.firstAggregate = 0
      // place.operatorType = e
    },
    toSetting () {
      this.$router.push({
        name: 'advanced',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId,
          positionId: this.placeInfo.position
        }
      })
    },
    // 时间改变事件
    ChangeDate (date, dateString) {
      this.query.dateEnd = dateString[1]
      this.query.dateStart = dateString[0]
      this.rangePickerDate = date
    },
    // 获取应用列表
    async getAppList () {
      this.isLoading = true
      const resp = await getAppList()
      this.appList = resp.data || []
      if (this.$route.query.appId) {
        this.query.appId = this.$route.query.appId
      } else {
        this.query.appId = this.appList[0].id
      }
      await this.getPlaceList()
    },
    // 获取广告位列表
    async getPlaceList () {
      const { data = [] } = await getPlaceListByAppId(this.query.appId)
      this.placeList = data
      /*
        1. 路由信息带有广告位ID
        2. 广告位列表中包含路由信息中的广告位ID
      */
      if (
        this.$route.query.placeId &&
        this.placeList.findIndex((item) => item.id === this.$route.query.placeId) !== -1
      ) {
        this.query.placeId = this.$route.query.placeId
      } else {
        this.query.placeId = (this.placeList[0] || {}).id
      }
      this.handleFirst()
      this.changeRoute()
      this.isLoading = false
    },
    // 处理打开引导框
    handleFirst () {
      if (!this.query.placeId) return
      const placeInfo = this.placeList.find((item) => item.id === this.query.placeId)
      if (+placeInfo.firstAggregate === 1 && this.lastPlace !== this.query.placeId) {
        this.isGuidan = true
        this.platIds = []
        this.firstModalVisible = true
        usedFirstAggergate({
          placeId: placeInfo.id
        }).then((res) => {
          if (res.code === 200) {
            // const place = this.placeList.find((item) => this.query.placeId === item.id)
            // place.firstAggregate = '0'
          }
        })
      }
      this.lastPlace = this.query.placeId
    },
    // 路由保留信息
    changeRoute () {
      // 得到运营方式
      if (this.query.placeId) {
        const placeInfo = this.placeList.find((item) => item.id === this.query.placeId)
        this.operatorType = +placeInfo.operatorType
      }
      this.$route.name === 'config' && this.$router.push({
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId
        }
      })
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 弹窗一确定事件
    modalNext1 (ids) {
      this.firstModalVisible = false
      // 引导模式
      this.platIds = ids
      this.$nextTick(() => {
        // 打开弹窗二
        this.secondModalVisible = true
      })
    },
    // 弹窗二确定事件
    modalNext2 () {
      this.secondModalVisible = false
      // if (this.isGuidan) {
      //   this.thirdlyModalVisible = true
      // } else {
      //   // 新对象，便于子组件监听
      //   this.query = {
      //     ...this.query
      //   }
      // }
      this.query = {
        ...this.query
      }
    },
    handleGoPrev (platIds) {
      this.secondModalVisible = false
      this.platIds = platIds
      this.$nextTick(() => {
        this.firstModalVisible = true
      })
    },
    editSource () {
      this.isGuidan = false
      this.platIds = []
      this.firstModalVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.config-container {
  padding: 0 10px;
  // min-width: 1280px;
  .config_header {
    background: #fff;
    padding-top: 20px;
    position: relative;
    margin-bottom: 10px;
    height: 70px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    .search_row {
      .col_item {
        display: flex;
        line-height: 35px;
        margin: 0 10px;
        .label {
          display: block;
          min-width: 60px;
          text-align: center;
        }
      }
    }
    .config_btn {
      line-height: 35px;
      height: 35px;
      width: 200px;
      display: flex;
      user-select: none;
      cursor: pointer;
      .radio-item {
        flex: 1;
        text-align: center;
        color: #cecece;
        border: 1px solid #cecece;
        display: flex;
        justify-content: center;
        align-items: center;
        .svg-icon {
          width: 14px;
          margin-right: 3px;
        }
        &:nth-child(1) {
          border-radius: 3px 0 0 3px;
          border-right: none;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
          border-left: none;
        }
        &.isactive {
          border: 1px solid @primary-color;
          background-color: @primary-color;
          color: #fff;
        }
      }
      // margin: 30px 0 0 0;
      // position: absolute;
      // right: 20px;
      // top: 0;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // & > span {
      //   margin-left: 5px;
      // }
    }
  }
  .config_main {
    min-height: calc(100vh - 210px);
    position: relative;
    .main_left_main {
      position: absolute;
      border-radius: 5px;
      margin-right: 10px;
      padding: 0 10px 10px 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      height: 70vh;
      transition: all 0.5s;
      .main_left_header {
        transition: all 0.5s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 13px;
          font-weight: 600;
        }
        .add_group_btn {
          transition: all 0.5s;
          overflow: hidden;
        }
      }
      .main_left_group_list {
        flex-grow: 1;
        height: 0;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        &.ant-menu-vertical {
          border-right: none;
        }
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          background: fade(@primary-color, 20%);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
        .ant-menu.ant-menu-vertical {
          .ant-menu-item {
            height: 35px;
            line-height: 35px;
            padding-right: 0;
            &.ant-menu-item-selected {
              background-color: fade(@primary-color, 5%);
              &::before {
                content: '';
                height: 100%;
                position: absolute;
                left: 0;
                border-radius: 5px;
                border-left: 3px solid @primary-color;
              }
            }
            .group_list_item {
              display: flex;
              justify-content: space-between;
              .group_name {
                font-size: 12px;
                flex-grow: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                .ab_dot {
                  display: inline-block;
                  height: 12px;
                  width: 24px;
                  vertical-align: super;
                  font-size: 12px;
                  line-height: 12px;
                  transform: scale(0.65);
                  text-align: center;
                  color: #fff;
                  background-color: red;
                  border-radius: 5px 5px 5px 0;
                }
              }
              .group_operation {
                transition: all 0.5s;
                overflow: hidden;
                .icon {
                  cursor: pointer;
                  transition: opacity 0.1s;
                  &:hover {
                    opacity: 0.5;
                  }
                  &.edit_icon {
                    color: #666;
                  }
                  &.delete_icon {
                    color: red;
                  }
                }
              }
            }
          }
        }
      }
      .main_left_footer {
        margin-top: 10px;
        cursor: pointer;
        height: 30px;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        background: #eee;
        border-radius: 10px;
      }
    }
    .main_right {
      transition: all 0.5s;
      overflow: hidden;
      background: #fff;
      border-radius: 5px;
      padding: 15px;
    }
  }
}
::v-deep {
  .ant-menu-vertical {
    border-right: none;
  }
}
</style>
